import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const About: React.FC<PageProps<DataProps>> = ({ data, path }) => (
  <Layout>
    <SEO title="About" />
      <h1>Profiles</h1>
      <ul>
          <li >
              <a href="https://www.linkedin.com/in/ed-belisle-697184/"
                 target="_blank">
                  LinkedIn</a>
          </li>
          <li >
              <a href="https://www.coursera.org/user/7c188b73273f845a561dc38b4eddd717"
                 target="_blank">
                  Coursera</a>
          </li>
      </ul>  </Layout>
)

export default About


